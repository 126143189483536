var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block__content comment-list" },
    [
      _vm._l(_vm.data, function (item, key) {
        return _c(
          "div",
          {
            key: item.id,
            class:
              "block--review single-comment justify-content-between d-flex mb-30" +
              (key % 2 != 0 ? " ml-30" : ""),
          },
          [
            _c("div", { staticClass: "user justify-content-between d-flex" }, [
              _c("div", { staticClass: "thumb text-center" }, [
                _c("img", {
                  attrs: { src: item.user_avatar, alt: item.user_name },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "font-heading text-brand d-block" }, [
                  _vm._v(_vm._s(item.user_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mb-10" },
                  [
                    _c("div", [
                      item.ordered_at
                        ? _c(
                            "span",
                            { staticClass: "font-sm text-muted d-block" },
                            [_vm._v(_vm._s(item.ordered_at))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "font-xs text-muted" }, [
                        _vm._v(_vm._s(item.created_at)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-rate d-inline-block" }, [
                      _c("div", {
                        staticClass: "product-rating",
                        style: { width: item.star * 20 + "%" },
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mb-10" }, [
                  _vm._v(_vm._s(item.comment)),
                ]),
                _vm._v(" "),
                item.images && item.images.length
                  ? _c(
                      "div",
                      { staticClass: "block__images" },
                      _vm._l(item.images, function (image, index) {
                        return _c(
                          "a",
                          { key: index, attrs: { href: image.full_url } },
                          [
                            _c("img", {
                              staticClass: "img-responsive rounded h-100",
                              attrs: {
                                src: image.thumbnail,
                                alt: image.thumbnail,
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", { staticClass: "review__loading" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.data.length
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", [_vm._v(_vm._s(_vm.__("No reviews!")))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-area mt-15 mb-md-5 mb-lg-0" },
        [
          _c("pagination", {
            attrs: { data: _vm.meta },
            on: { "on-click-paging": _vm.onClickPaginate },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "half-circle-spinner" }, [
      _c("div", { staticClass: "circle circle-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "half-circle-spinner" }, [
          _c("div", { staticClass: "circle circle-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "circle circle-2" }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "post-list mb-4 mb-lg-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.data, function (item) {
          return !_vm.isLoading && _vm.data.length
            ? _c(
                "article",
                { key: item.id, staticClass: "wow fadeIn animated col-lg-6" },
                [
                  _c("div", { staticClass: "d-md-flex d-block" }, [
                    _c(
                      "div",
                      {
                        staticClass: "post-thumb d-flex mr-15 border-radius-10",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "color-white",
                            attrs: { href: item.url },
                          },
                          [
                            _c("img", {
                              staticClass: "border-radius-10",
                              attrs: { src: item.image, alt: item.name },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "post-content" }, [
                      _c("div", { staticClass: "entry-meta mb-5 mt-10" }, [
                        _c(
                          "a",
                          {
                            staticClass: "entry-meta meta-2",
                            attrs: { href: item.category.url },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "post-in text-danger font-x-small text-uppercase",
                              },
                              [_vm._v(_vm._s(item.category.name))]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "h4",
                        { staticClass: "post-title mb-25 text-limit-2-row" },
                        [
                          _c("a", { attrs: { href: item.url } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "entry-meta meta-1 font-xs color-grey mt-10 pb-10",
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "post-on" }, [
                              _c("i", { staticClass: "far fa-clock" }),
                              _vm._v(" " + _vm._s(item.created_at)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "hit-count has-dot" }, [
                              _vm._v(
                                _vm._s(item.views) +
                                  " " +
                                  _vm._s(_vm.__("Views"))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("a", { attrs: { href: item.url } }, [
                            _vm._v(_vm._s(_vm.__("Read more")) + " "),
                            _c("i", {
                              staticClass: "fa fa-arrow-right font-xxs ml-5",
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
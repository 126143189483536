var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          {
            directives: [{ name: "carousel", rawName: "v-carousel" }],
            staticClass:
              "carousel-6-columns-cover arrow-center position-relative wow fadeIn animated",
            attrs: {
              "data-items-xxl": _vm.scrollItems,
              "data-items-xl": "6",
              "data-items-lg": "4",
              "data-items-md": "4",
              "data-items-sm": "1",
            },
          },
          [
            _c("div", {
              staticClass:
                "slider-arrow slider-arrow-3 carousel-6-columns-arrow",
              attrs: { id: "carousel-6-columns-3-arrows" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "carousel-slider-wrapper carousel-6-columns text-center",
                attrs: { id: "carousel-6-columns-3" },
              },
              _vm._l(_vm.data, function (item) {
                return _c("div", { staticClass: "brand-logo" }, [
                  item.website
                    ? _c("a", { attrs: { href: item.website } }, [
                        _c("img", {
                          staticClass: "img-grey-hover",
                          attrs: { src: item.logo, alt: item.name },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !item.website
                    ? _c("img", {
                        staticClass: "img-grey-hover",
                        attrs: { src: item.logo, alt: item.name },
                      })
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "half-circle-spinner" }, [
      _c("div", { staticClass: "circle circle-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
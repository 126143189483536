var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row justify-content-center" },
    [
      _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.data, function (item) {
        return !_vm.isLoading && _vm.data.length
          ? _c("div", {
              directives: [{ name: "countdown", rawName: "v-countdown" }],
              key: item.id,
              staticClass: "col-xxl-3 col-xl-4 col-lg-6 col-md-6",
              domProps: { innerHTML: _vm._s(item) },
            })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "half-circle-spinner" }, [
      _c("div", { staticClass: "circle circle-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.data, function (item, index) {
          return !_vm.isLoading && _vm.data.length
            ? _c(
                "div",
                {
                  key: index,
                  class:
                    "col-xl-" +
                    12 / _vm.data.length +
                    " col-lg-6 col-md-6 mb-md-0",
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "section-title style-1 mb-30 animated animated",
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "product-list-small animated animated" },
                    _vm._l(item.products, function (product, i) {
                      return _c(
                        "article",
                        {
                          key: "" + i + index + product.id,
                          staticClass: "row align-items-center hover-up",
                        },
                        [
                          _c("figure", { staticClass: "col-md-4 mb-0" }, [
                            _c("a", { attrs: { href: product.url } }, [
                              _c("img", {
                                attrs: {
                                  src: product.image,
                                  alt: product.name,
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 mb-0" }, [
                            _c("h6", [
                              _c("a", { attrs: { href: product.url } }, [
                                _vm._v(_vm._s(product.name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            product.reviews_count
                              ? _c(
                                  "div",
                                  { staticClass: "product-rate-cover" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-rate d-inline-block",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "product-rating",
                                          style:
                                            "width: " +
                                            product.reviews_avg * 20 +
                                            "%",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-small ml-5 text-muted",
                                      },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(product.reviews_count) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "product-price" }, [
                              !product.sale_price ||
                              product.sale_price === product.price
                                ? _c("span", [_vm._v(_vm._s(product.price))])
                                : _vm._e(),
                              _vm._v(" "),
                              product.sale_price &&
                              product.sale_price !== product.price
                                ? _c("span", [
                                    _vm._v(_vm._s(product.sale_price)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              product.sale_price &&
                              product.sale_price !== product.price
                                ? _c("span", { staticClass: "old-price" }, [
                                    _vm._v(_vm._s(product.price)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "half-circle-spinner" }, [
      _c("div", { staticClass: "circle circle-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
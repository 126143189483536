var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "fl_center" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "half-circle-spinner" }, [
              _c("div", { staticClass: "circle circle-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "circle circle-2" }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._l(_vm.data, function (item) {
        return _vm.data.length
          ? _c("div", {
              key: item.id,
              class: "col-lg-" + 12 / _vm.limit + " col-md-4 col-12 col-sm-6",
              domProps: { innerHTML: _vm._s(item) },
            })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noData
    ? _c("div", { staticClass: "container" }, [
        !_vm.isLoading
          ? _c(
              "div",
              {
                staticClass:
                  "section-title style-2 wow animate__animated animate__fadeIn",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.title))]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: "row product-grid-" + _vm.perRow },
          _vm._l(_vm.data, function (item, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass:
                  "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-5 mb-sm-5",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "product-cart-wrap mb-30 wow animate__animated animate__fadeIn",
                    attrs: { "data-wow-delay": (key + 1) / 10 + "s" },
                  },
                  [
                    _c("div", { staticClass: "product-img-action-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "product-img product-img-zoom" },
                        [
                          _c("a", { attrs: { href: item.url } }, [
                            _c("img", {
                              staticClass: "default-img",
                              attrs: { src: item.image, alt: item.name },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "hover-img",
                              attrs: { src: item.hover_image, alt: item.name },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-action-1" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "action-btn hover-up js-quick-view-button",
                            attrs: {
                              "aria-label": _vm.__("Quick View"),
                              href: "#",
                              "data-url": item.urls.quick_view,
                            },
                          },
                          [_c("i", { staticClass: "fi-rs-eye" })]
                        ),
                        _vm._v(" "),
                        item.urls.wishlist
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "action-btn hover-up js-add-to-wishlist-button",
                                attrs: {
                                  "aria-label": _vm.__("Add To Wishlist"),
                                  href: "#",
                                  "data-url": item.urls.wishlist,
                                },
                              },
                              [_c("i", { staticClass: "fi-rs-heart" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.urls.compare
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "action-btn hover-up js-add-to-compare-button",
                                attrs: {
                                  "aria-label": _vm.__("Add To Compare"),
                                  href: "#",
                                  "data-url": item.urls.compare,
                                },
                              },
                              [_c("i", { staticClass: "fi-rs-shuffle" })]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "product-badges product-badges-position product-badges-mrg",
                        },
                        [
                          item.is_out_of_stock
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "background-color": "#000",
                                    "font-size": "11px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.__("Out Of Stock")))]
                              )
                            : item.product_labels && item.product_labels.length
                            ? _vm._l(item.product_labels, function (label, k) {
                                return _c(
                                  "span",
                                  {
                                    key: k,
                                    style: label.color
                                      ? "background-color: " + label.color + ""
                                      : "",
                                  },
                                  [_vm._v(_vm._s(label.name))]
                                )
                              })
                            : item.sale_percentage
                            ? _c("span", { staticClass: "hot" }, [
                                _vm._v(_vm._s(item.sale_percentage)),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-content-wrap" }, [
                      item.category && item.category.name
                        ? _c("div", { staticClass: "product-category" }, [
                            _c("a", { attrs: { href: item.category.url } }, [
                              _vm._v(_vm._s(item.category.name)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h2", [
                        _c("a", { attrs: { href: item.url } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-rate-cover" }, [
                        _c(
                          "div",
                          { staticClass: "product-rate d-inline-block" },
                          [
                            _c("div", {
                              staticClass: "product-rating",
                              style:
                                "width: " +
                                (item.reviews ? item.reviews.avg * 20 : 0) +
                                "%",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "font-small ml-5 text-muted" },
                          [
                            _vm._v(
                              " (" +
                                _vm._s(item.reviews ? item.reviews.count : 0) +
                                ")"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      item.vendor && item.vendor.name
                        ? _c("div", [
                            _c(
                              "span",
                              { staticClass: "font-small text-muted" },
                              [
                                _vm._v(_vm._s(_vm.__("Sold By")) + " "),
                                _c("a", { attrs: { href: item.vendor.url } }, [
                                  _vm._v(_vm._s(item.vendor.name)),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-card-bottom" }, [
                        _c("div", { staticClass: "product-price" }, [
                          !item.sale_price || item.sale_price === item.price
                            ? _c("span", [_vm._v(_vm._s(item.price))])
                            : _vm._e(),
                          _vm._v(" "),
                          item.sale_price && item.sale_price !== item.price
                            ? _c("span", [_vm._v(_vm._s(item.sale_price))])
                            : _vm._e(),
                          _vm._v(" "),
                          item.sale_price && item.sale_price !== item.price
                            ? _c("span", { staticClass: "old-price" }, [
                                _vm._v(_vm._s(item.price)),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        item.urls.add_to_cart
                          ? _c("div", { staticClass: "add-cart" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "action-btn add-to-cart-button add",
                                  attrs: {
                                    "aria-label": _vm.__("Add To Cart"),
                                    "data-id": item.id,
                                    "data-url": item.urls.add_to_cart,
                                    href: "#",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fi-rs-shopping-cart mr-5",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.__("Add")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
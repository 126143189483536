var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          {
            directives: [{ name: "carousel", rawName: "v-carousel" }],
            staticClass: "carousel-3-columns-cover position-relative",
            attrs: {
              "data-items-xl": "3",
              "data-items-lg": "3",
              "data-items-md": "2",
              "data-items-sm": "1",
            },
          },
          [
            _c("div", {
              staticClass:
                "slider-arrow slider-arrow-2 carousel-3-columns-arrow",
              attrs: { id: "carousel-3-columns-products-arrows" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "carousel-slider-wrapper carousel-3-columns",
                attrs: { id: "carousel-3-columns-products" },
              },
              _vm._l(_vm.data, function (item) {
                return !_vm.isLoading && _vm.data.length
                  ? _c("div", {
                      key: item.id,
                      staticClass: "product-cart-wrap small hover-up p-10",
                      domProps: { innerHTML: _vm._s(item) },
                    })
                  : _vm._e()
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "half-circle-spinner" }, [
      _c("div", { staticClass: "circle circle-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
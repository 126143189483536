var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("renderless-pagination", {
    attrs: { data: _vm.data },
    on: { "on-click-paging": _vm.onPaginationChangePage },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var data = ref.data
            var computed = ref.computed
            var pageButtonEvents = ref.pageButtonEvents
            return computed.hasPages
              ? _c("nav", {}, [
                  _c(
                    "ul",
                    { staticClass: "pagination" },
                    _vm._l(data.links, function (element, index) {
                      return _c(
                        "li",
                        {
                          key: index + element.url,
                          staticClass: "page-item",
                          class: {
                            active: element.active,
                            disabled: !element.url,
                          },
                          attrs: {
                            "aria-disabled": element.active ? "true" : null,
                          },
                        },
                        [
                          _c(
                            "a",
                            _vm._g(
                              {
                                staticClass: "page-link",
                                attrs: {
                                  href: element.url,
                                  rel:
                                    index == 0
                                      ? "previous"
                                      : index == data.links.length - 1
                                      ? "next"
                                      : null,
                                  "aria-label": element.label,
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    index == 0
                                      ? "«"
                                      : index == data.links.length - 1
                                      ? "»"
                                      : element.label
                                  ),
                                },
                              },
                              pageButtonEvents({
                                element: element,
                                page: element.label,
                              })
                            )
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e()
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      _vm._l(_vm.productCollections, function (item) {
        return _c(
          "li",
          {
            key: item.id,
            staticClass: "nav-item",
            attrs: { role: "presentation" },
          },
          [
            _c(
              "button",
              {
                class:
                  _vm.productCollection.id === item.id
                    ? "nav-link active"
                    : "nav-link",
                attrs: {
                  "data-bs-toggle": "tab",
                  "data-bs-target": "#" + item.slug,
                  type: "button",
                  role: "tab",
                  "aria-controls": item.slug,
                  "aria-selected": "true",
                },
                on: {
                  click: function ($event) {
                    return _vm.getData(item)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content wow fadeIn animated" }, [
      _vm.isLoading
        ? _c("div", { staticClass: "half-circle-spinner" }, [
            _c("div", { staticClass: "circle circle-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "circle circle-2" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              key: _vm.productCollection.id,
              staticClass: "tab-pane fade show active",
              attrs: {
                id: _vm.productCollection.slug,
                role: "tabpanel",
                "aria-labelledby": _vm.productCollection.slug + "-tab",
              },
            },
            [
              _c(
                "div",
                { staticClass: "row product-grid-4" },
                _vm._l(_vm.data, function (item) {
                  return _vm.data.length
                    ? _c("div", {
                        key: item.id,
                        staticClass: "col-lg-3 col-md-4 col-12 col-sm-6",
                        domProps: { innerHTML: _vm._s(item) },
                      })
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container wow animate__animated animate__fadeIn" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "section-title" }, [
            _c("div", { staticClass: "title" }, [
              _c("h3", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "slider-arrow slider-arrow-2 flex-right carousel-10-columns-arrow",
              attrs: { id: "carousel-10-columns-arrows" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              directives: [{ name: "carousel", rawName: "v-carousel" }],
              staticClass: "carousel-10-columns-cover position-relative",
              attrs: {
                "data-items-xxl": _vm.scrollItems,
                "data-items-xl": "6",
                "data-items-lg": "4",
                "data-items-md": "3",
                "data-items-sm": "2",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "carousel-slider-wrapper carousel-10-columns",
                  attrs: { id: "carousel-10-columns" },
                },
                _vm._l(_vm.data, function (item, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "card-2 wow animate__animated animate__fadeInUp",
                      style: item.background_color
                        ? "background-color:" + item.background_color
                        : "",
                      attrs: { "data-wow-delay": (key + 1) / 10 + "s" },
                    },
                    [
                      _c(
                        "figure",
                        { staticClass: "img-hover-scale overflow-hidden" },
                        [
                          _c("a", { attrs: { href: item.url } }, [
                            _c("img", {
                              attrs: { src: item.thumbnail, alt: item.name },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("h6", [
                        _c(
                          "a",
                          { attrs: { href: item.url, title: item.name } },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.products_count_text))]),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }